<template>
  <b-card-actions
    :title="$t('Preparation Time')"
    action-collapse
    collapsed
  >
    <b-row>
      <b-col>
        <b-form-group class="mb-0">
          <validation-observer ref="time">
            <validation-provider
              #default="{ errors, failedRules }"
              name="preparation_time"
              :rules="`integer|max:${textLimit}`"
            >
              <b-input-group
                :append="$t('min')"
                style="width: 124px"
              >
                <b-form-input
                  :value="value.time"
                  placeholder="90"
                  :state="errors.length ? false : null"
                  @input="updateValue('time', $event)"
                  @blur="onBlurTime"
                />
              </b-input-group>
              <small class="text-danger">
                <template v-if="failedRules.integer">{{ $t('validationErrorIntegerPreparationTime') }}</template>
                <template v-else-if="failedRules.max">{{ $t('validationErrorMaxPreparationTime', { slot: textLimit }) }}</template>
                <template v-else>{{ errors[0] }}</template>
              </small>
            </validation-provider>
          </validation-observer>
        </b-form-group>
      </b-col>
      <b-col
        md="auto"
        class="pt-50"
      >
        <b-form-checkbox
          :checked="value.isInMenus"
          @input="onInputIsInMenus"
          @change="onChangeIsInMenus"
        >
          {{ $t('cardPreparationTimeLabel') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  integer,
  max,
} from '@validations'
import mixinCardCollapsed from '@/mixins/card-collapsed'

export default {
  name: 'CardPreparation',
  components: {
    BCardActions,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,

    // validations
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [
    mixinCardCollapsed,
  ],
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        time: '',
        isInMenus: false,
      }),
    },
  },
  data() {
    return {
      textLimit: 3,

      // validation rules
      integer,
      max,
    }
  },
  methods: {
    updateValue(key, val) {
      this.$emit('input', {
        ...this.value,
        [key]: val,
      })
    },
    async onBlurTime() {
      const isValid = await this.$refs.time.validate()
      if (isValid) {
        this.$emit('savePreparationTime')
      }
    },
    onInputIsInMenus(event) {
      this.updateValue('isInMenus', event)
    },
    onChangeIsInMenus() {
      this.$emit('savePreparationIsInMenus')
    },
  },
}
</script>
